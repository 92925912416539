body {
  margin: 0;
  background-color: #f7f8f6;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

a {
  text-decoration: none;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(160, 160, 160);
}

*::-webkit-scrollbar-track {
  background-color: #f7f8f6;
}

*::-webkit-scrollbar {
  width: 10px;
  /* overrides material-table */
  height: 10px !important;
  background-color: #f7f8f6;
}

*::-webkit-scrollbar-thumb {
  /* overrides material-table */
  border-radius: 10px !important;
  background-color: rgb(192, 192, 192) !important;
  border: 0 !important;
}

*[tabindex]:focus {
  outline: 0;
}
